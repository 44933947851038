.modal-backdrop.show {
  display: none !important;
}

.table-container {
  display: block;
  /* Show the table container by default */
}

.ver_btn_ {
  gap: 2px;
  margin: 0px 25px;
  border: navajowhite;
  border-radius: 6px;
  padding: 3px 12px;
}

.accordion-container {
  display: none;
  /* Hide the accordion container by default */
}

/* When showAccordion is true, show the accordion cregistrationForm.jsontainer and hide the table container */
.visible {
  display: block;
}

/* When showAccordion is false, hide the accordion container and show the table container */
.hidden {
  display: none;
}

.close_btn {
  display: flex;
  justify-content: space-between;
}

.close_bt {
  display: flex;
  justify-content: space-between;
}

.lable {
  margin: 0px 0px 3px 0px;
}

.add_btn {
  display: flex;
  justify-content: end;
}

.link {
  color: black !important;
}

.input-label {
  font-weight: bold;
  /* Adjust styling as needed */
  margin-right: 10px;
  /* Adjust spacing as needed */
}

.spn_inActive {
  background: red;
  padding: 0px 5px 3px 6px;
  border-radius: 4px;
}

.div_top {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 10px 0px;
  border-bottom: 0.5px solid #e2dcdc;
}

.btn_view {
  background: #00d2ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnView {
  background: #00d2ff;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.tab_er {
  display: flex;
  width: 100%;
}

.floating-label {
  position: absolute;
  top: -10px;
  /* Adjust the top position as needed */
  left: 10px;
  /* Adjust the left position as needed */
  background-color: white;
  /* Adjust background color as needed */
  padding: 0 5px;
  transition: top 0.2s, font-size 0.2s;
  pointer-events: none;
}

/* Style for the floating label when the input is focused or has a value */
.custom-select:focus+.floating-label,
.custom-select:valid+.floating-label {
  top: -25px;
  /* Adjust the top position when focused */
  font-size: 12px;
  /* Adjust font size when focused */
}

.identification_btn {
  display: flex;
  // margin: 17px 17px;
  margin: 24px 17px 0px 17px;
  justify-content: center;
}

@media (max-width: 768px) {
  .next_btn {
    width: 60%;
    background: #3c4b64 !important;
    border: none !important;
    color: #fff !important;
  }
}

@media (min-width: 768px) {
  .next_btn {
    width: 20%;
    background: #3c4b64 !important;
    border: none !important;
    color: #fff !important;
  }
}

.add-btn {
  width: 10%;
  background: #3c4b64 !important;
  border: none !important;
  color: #fff !important;
}

.three-btn {
  width: 25%;
  background: #3c4b64 !important;
  border: none !important;
  color: #fff !important;
}

// .icon-btn{
//   background: #3c4b64 !important;
//   border: none !important;
//   color: #fff !important;
//   }
// .btn-container{
//   width: 30% !important;
//   display: flex !important;
//   justify-content: space-between !important;
// }
.login-btn {
  font-weight: 700;
  width: 50%;
  border: 2px solid #3c4b64 !important;
  background-color: #3c4b64 !important;
}

.ref_personal {
  margin: 0px 0px 4px 0px;
  font-size: 20px;
  font-weight: 500;
}

.gap {
  margin: 21px 0px 0px 0px;
}

//  =======================edit and uptate modal design =================
/* Floating Label Styles */
.floating-label {
  position: relative;
  margin-bottom: 1rem;
}

.floating-label input {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.w_12 {
  width: 12% !important;
}

.floating-label label {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 0.2s ease all;
}

.floating-label input:focus~label,
.floating-label input:valid~label {
  top: -20px;
  font-size: 0.8rem;
  color: #007bff;
  /* Change to the color you prefer */
}

.custom-date {
  width: 359px;
  padding: 7px 12px;
  border-radius: 5px;
  border: 1px solid #dee2e6;
}

.text-danger {
  position: absolute;
  top: 56px;
  margin-top: 12px;
}

.approve {
  border: 1px solid white;
  padding: 0px 5px 4px 5px;
  border-radius: 6px;
  background: #50f750;
  color: black;
  cursor: pointer;
}

.reject {
  border: 1px solid white;
  padding: 0px 5px 4px 5px;
  border-radius: 6px;
  background: #ff0435;
  color: #0d0a0a;
  cursor: pointer;
}

.pending {
  border: 1px solid white;
  padding: 0px 5px 4px 5px;
  border-radius: 6px;
  background: #ffff00;
  color: #0d0a0a;
  cursor: pointer;
}

.pending_status {
  display: flex;
  gap: 8px;
}

.err_select {
  margin: -17px 0px 0px 0px;
}

HEAD .err_file {
  margin: 138px 0px;
}

.left_loandetail {
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 23px;
  margin: 7px 0px;
}

.left_detail_content {
  display: flex;
  justify-content: space-between;
  margin: 15px 15px 13px 15px;
  border-top: 1px solid #c8b6b6;
  padding: 6px 0px 0px 0px;
  font-weight: 500;
}

.left_color {
  color: #0da8fdba;
}

.payment_details {
  margin: 15px 15px 13px 15px;
  border-top: 1px solid #c8b6b6;
  padding: 6px 0px 0px 0px;
  font-weight: 500;
}

.lft_paymentdetail {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 20px;
  margin: 4px 0px;
}

// ================= paymentRecords ====================
.paymentrecord2 {
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
}

.paymentrecord {
  display: flex;
  justify-content: center;
  margin: 0px 0px 12px 46px;
  border: 1px solid #ccbcbc;
  border-radius: 10px;
  width: 93%;
  background-color: #f4f4f4;

  .serch_bar {
    width: 96%;
    margin: 10px 0px 0px 0px;
  }

  .spn {
    font-size: small;
  }

  .page {
    display: flex;
    justify-content: space-between;
  }
}

.spn {
  font-size: small;
}

.nav-tabs .nav-link :active {
  color: #fff;
  background-color: #3c4b64;
  border-color: #3c4b64;
  border-radius: inherit !important;
}

.nav-tabs .nav-link:focus {
  border-radius: inherit !important;
}

// td,
tfoot,
th,
thead // tr

  {
  // background-color: #303c50 !important;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  color: #fff !important;
}

.table_emi_section {
  background-color: #303c50;
}

.loading_data {
  font-size: 20px;
  font-weight: 800;
  color: #0d0a0a;
  padding: 50px 0;
}

.dashboard_data {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #0d0a0a !important;
}

.btn-close {
  padding-bottom: 10px;
}

.loginSectionData {
  color: #3c4b64 !important;
  font-weight: 700;
  display: flex;
  justify-content: center;
}

.text-right {
  display: flex;
  justify-content: center;
}

.forgotPassword {
  color: #3c4b64 !important;
  text-decoration: none !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

@media print {

  /* Define your print-specific styles here */
  .no-print {
    display: none;
    /* Hide elements with the "no-print" class when printing */
  }
}

.staffform_header {
  font-size: 20px;
  display: flex;
  font-weight: 600;
  margin-top: 15px;
  border-bottom: 1px solid #807b7b;
  justify-content: space-between;
}

.company_header {
  font-size: 20px;
  font-weight: 600;
  margin: 5px 0px;
  border-bottom: 2px solid black;
}

.image_name {
  display: flex;
  margin: 9px 10px;

  .img_tag {
    border-radius: 33px;
    width: 18%;
  }
}

.spn {
  padding: 14px 18px;
  font-size: 14px;
}

.spn_data {
  display: flex;
  margin: 19px 0px 0px 0px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.fullWidthRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.header_tab {
  display: flex;
  margin: 11px 0px 14px 0px;
  justify-content: center;
}

.header_tab_btn {
  display: flex;
  margin: 13px 2px;

  .btn {
    background: #ff0000b8;
    padding: 2px 20px;
    border: none;
  }
}

.fullPageModal {
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view_spn {
  background: #edeff6;
  padding: 3px 8px;
  border-radius: 8px;
}

.delete_spn {
  background: #e70808;
  height: 31px;
  padding: 3px 9px;
  border-radius: 8px;
  color: #fff;
}

.edit_spn {
  background: rgb(243 235 181 / 96%);
  height: 31px;
  padding: 2px 10px;
  border-radius: 8px;
}

.download_Santialletter {
  display: flex;
  justify-content: center;
  margin: 15px 0px;
}

.sections {
  display: flex;
  justify-content: space-around;
  margin: 10px;
}

.header_head_btn {
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.btn_profile {
  display: flex;
  margin: 6px -7px -14px 0px;
  width: 18%;
}

.body_d {
  display: flex;
  margin: 22px 0px 0px 0px;
}

//===========================================
// scss for the statement form::after
//===========================================
$color_1: #1e3799;
$color_2: #0b5d94;
$color_3: #03a9ea;
$color_4: #fff;
$color_5: white;
$color_6: #12b32d;
$font-family_1: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
  "Lucida Sans", Arial, sans-serif;
$background-color_1: #cfebf4;
$background-color_2: transparent;
$background-color_3: #deecef;
$background-color_4: #cedfe6;
$background-color_5: #f7f7f7;
$background-color_6: #3a81ad;

/* css for form header */
/* body part css table 50% */
/* headbar-new */
/* statement-form TABLE */
.global-margin-top {
  margin-top: 5%;
}

.form-wrapper {
  margin-top: 0px;
}

.form-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-family_1;
  flex-direction: column;
}

.statement-form2 {
  margin-left: 0% !important;
  margin-right: 0% !important;
}

// .statement-form {
//   width: 95%;
//   margin-left: 2.5%;
//   margin-right: 2%;
//   height: auto;
//   display: flex;
//   flex-direction: column;
//   margin-top: 20px;
//   border: 2px solid #e4e4e4;
//   margin-bottom: 2%;
//   padding-bottom: 1%;
// }

@media (max-width: 768px) {
  .statement-form {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 2px solid #e4e4e4;
    margin-bottom: 2%;
    padding-bottom: 1%;
    font-size: 8px !important;
  }

  .statement-form img {
    width: 50px !important;
  }

  .statement-form .info-headcopy {
    font-size: 10px !important;
  }

  .statement-form .info-inputcopy {
    font-size: 10px !important;
  }

  .statement-form .input-span {
    font-size: 10px !important;
  }

  .statement-form .info-head {
    font-size: 10px !important;
    font-weight: 100 !important;
  }

  .statement-header h3 {
    font-size: 10px;
  }

  .statement-header {
    display: flex !important;
    justify-content: space-between !important;
  }

  .statement-header .stmt-logo {
    width: 60px !important;
  }

  .pdfbtn {
    width: 100px;
  }

  .pdfbtn button {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .statement-form {
    width: 95%;
    margin-left: 2.5%;
    margin-right: 2%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    border: 2px solid #e4e4e4;
    margin-bottom: 2%;
    padding-bottom: 1%;
  }

  hr {
    border: none;
    height: 1.2px;
    background-color: $background-color_1;
  }

  .table-div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th {
    background-color: $background-color_6;
    color: $color_5;
    text-align: left;
    // padding: 5px;
  }

  td {
    background-color: $background-color_5;
    padding: 5px;
  }

  .align-right {
    text-align: right;
  }

  .green-text {
    color: $color_6;
  }
}

.statement-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.8%;
  margin-top: 2%;
  color: $color_1;
  font-weight: 600;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;

  .stmt-logo {
    width: 110px;
  }

  img {
    width: 100%;
  }
}

.maxima {
  margin-top: 2px;
  width: 100%;
  height: auto;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.fifty {
  width: 50%;
}

.stmt-info {
  width: 100%;
  margin-bottom: 1px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.info-head {
  width: 50%;

  p {
    color: $color_2;
    font-weight: 600;
    margin-bottom: 0rem;
  }
}

.info-input {
  width: 50%;
  color: $color_3;

  input {
    margin-left: 10px;
    border: none;
    background-color: $background-color_2;
    outline: none;
    border-bottom: none;
    transition: border-color 0.3s ease;
    padding: 0.5px;
    color: $color_3;
    font-size: 14px;

    &:focus {
      border-bottom: 1px solid #1e90ff;
    }
  }

  .input-span {
    margin-left: 10px;
    border: none;
    background-color: $background-color_2;
    outline: none;
    border-bottom: none;
    transition: border-color 0.3s ease;
    padding: 0.5px;
    color: $color_3;
    font-size: 14px;
  }
}

.stmt-headbar {
  width: 100%;
}

.stmt-headbar1 {
  display: flex;
  flex-direction: row;
  background-color: $background-color_3;
  padding-left: 10px;
  padding-right: 10px;
}

.stmt-headbar2 {
  background-color: $background-color_4;
  color: $color_3;
  text-align: right;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 18px;
}

.maximacopy {
  width: 100%;
  height: auto;
  display: flex;
}

.maxima50 {
  width: 100%;
  height: auto;
  display: flex;
}

.fiftycopy {
  width: 50%;
  border: 1px solid #cff0fb;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $background-color_5;
}

.fiftycopyauto {
  width: 524px;
}

.new_sp {
  // border: 1px solid;
  padding: 1px -1px 2px 5px;
  border-radius: 4px;
  margin: 10px 2px 1px 18px;
  font-size: 17px;
}

.sminfo {
  margin-bottom: 15px;
  font-size: 17px;
  display: flex;
  flex-direction: row;
}

.monus {
  display: flex;
  justify-content: space-around;
  background: #eafff35c;
  font-family: serif;
  /* color: black; */
  border-radius: 18px;
}

.monusd {
  background: #eafff35c;
  font-family: serif;
  /* color: black; */
  border-radius: 18px;
}

.fiftycopyauto-box {
  width: 100%;
  height: fit-content;
  border: 1px solid #cff0fb;
  background-color: $background-color_5;
}

.fiftycopyhalf {
  width: 100%;
  height: fit-content;
  border: 1px solid #cff0fb;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $background-color_5;
}

.stmt-infocopy {
  width: 100%;
  height: auto;
  margin-bottom: 1px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.info-headcopy {
  width: 50%;
  padding-left: 10px;

  p {
    color: $color_3;
    margin-bottom: 0rem;
  }
}

.info-headcopy-in {
  width: 50%;
  padding-left: 0px !important;
}

.info-inputcopy {
  width: 50%;
  color: $color_3;

  input {
    margin-left: 10px;
    border: none;
    background-color: $background-color_2;
    outline: none;
    border-bottom: none;
    transition: border-color 0.3s ease;
    color: $color_3;
    font-size: 14px;

    &:focus {
      border-bottom: 1px solid #1e90ff;
    }
  }


  .input-span {
    margin-left: 10px;
    border: none;
    background-color: $background-color_2;
    outline: none;
    border-bottom: none;
    transition: border-color 0.3s ease;
    color: $color_3;
    font-size: 14px;
  }

  textarea {
    margin-left: 10px;
    border: none;
    background-color: $background-color_2;
    outline: none;
    border-bottom: none;
    transition: border-color 0.3s ease;
    padding: 0.5px;
    color: $color_3;
    font-size: 14px;
    min-height: 18px;
    resize: vertical;
    text-align: top;

    &:focus {
      border-bottom: 1px solid #1e90ff;
    }
  }
}

.stmt-headbarhalf {
  width: 100%;
}

.stmt-headbar-iii {
  width: 100%;
}

.stmt-headbar1-iii {
  display: flex;
  flex-direction: row;
  background-color: $background-color_3;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 18px;
}

.stmt-headbar2-iii {
  background-color: $background-color_4;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 18px;
  display: flex;
  flex-direction: row !important;
}

.stmt-headbar3-iii {
  background-color: $background-color_6;
  color: $color_4;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 18px;
}

.stmt-headbar4-iii {
  background-color: $background-color_6;
  color: $color_4;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  min-height: 18px;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.payment_a {
  font-size: 20px;
  font-weight: 500;
}

.payment_b {
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .pagin-div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
}

@media (min-width: 768px) {
  .pagin-div {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    //margin: 15px 17px -18px 14px;
  }
}

.group_top_header {
  display: flex;
  justify-content: space-between;
}

.group_details {
  font-size: 17px;
  font-weight: 600;
}

.searchinput {
  margin: 21px 0px 26px 0px;
}

.cursor {
  cursor: pointer;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.lable-name {
  color: black;
  font-weight: 500;
}

.content-name {
  font-weight: 500;
  color: cornflowerblue;
}

.customer_details {
  margin: 22px 72px;
}

//custom Design
thead tr th {
  // border: 1px solid !important;
  //  padding: 0px !important;
  // text-align: center;
}

td {
  // border: 1px solid !important;
  border: 0px !important;
  padding: 4px 8.75px !important;
  // text-align: center;

}

tr:nth-child(odd) {
  // background: #f0f8ff;
}

.body {
  background-color: #f6f4f1 !important;
}

input,
select {
  border-radius: 0px !important;
  border: 1px solid !important;
}

.divider_gurantor {
  margin: 10px 0px;
  border-bottom: 3px solid black;
  font-size: 23px;
  font-weight: 500;
  font-style: oblique;
}

.btn_verify {
  margin: 17px -55px;
}

.cus_details {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
}

.cus_data {
  margin: 9px 0px;
  padding: 3px 0px;
}

.cus_ap {
  color: cornflowerblue;
  padding: 0px 23px;
}

.color_heading {
  color: red;
}

.error_er {
  margin: 8px 14px 24px -28px;
  font-size: 20px;
  font-family: serif;
}

.active-projects thead tr th {
  text-align: left;
}