// Here you can add other styles


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

// tooltip
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    opacity: 0.8;
    z-index: 1;
    display: none;
}

.tooltip-container:hover .tooltip {
    display: block;
}

// hover icon
.viewIcon:hover {
    text-decoration: none;
    color: #7ED50E;
}

.updateIcon:hover {
    text-decoration: none;
    color: #efef0f;
}

.deleteIcon:hover {
    text-decoration: none;
    color: #e81a1a;
}

.table {
    margin-bottom: 0rem !important;
}

.pt-20 {
    padding-top: 20px
}

.sticky-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}

.content {
    padding-top: 30px;
}

.mb-14 {
    margin-bottom: 14px
}

@media (max-width: 768px) {
    .plr-30 {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }

    .plr-20 {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
}

@media (min-width:768px) {
    .plr-30 {
        padding-left: 30% !important;
        padding-right: 30% !important;
    }

    .plr-20 {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }
}



.mr-10 {
    margin-right: 10px;
}

.tr {
    background-color: #e81a1a;
    color: #000;
}

td {
    background-color: transparent !important;
    color: #000 !important
}

.header-details {
    justify-content: space-between;
    align-items: center;
}

.p-0 {
    padding: 0px
}

.table-dark {
    --bs-table-hover-bg: #fff9f1cf !important;
}

.tabs_customer_details li {
    background-color: #ffc107ad;
    margin: 4px;
    border-radius: 10px;
}

.tabs_customer_details li:hover {
    background: #0d6efd;
}

.tabs_customer_details li .nav-link:hover {
    background: #0d6efd;
}

// .nav-group-items .nav-item .nav-link {
//     padding-left: 40% !important;
// }

.custom-theme-color {
    background-color: #222b40;
}

.sidebar-nav .nav-group-toggle {
    color: #fff
}

.sidebar-nav .nav-link {
    color: #fff
}

.table td {
    font-size: 13px;
}